import { Component, DestroyRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { SiteConfigService } from '@garmin-avcloud/avcloud-fly-web-common/config';
import { ModalButton, ModalComponent } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { addDays } from 'date-fns';
import { map, catchError, of, EMPTY } from 'rxjs';
import { CartItem } from 'src/app/shared/models/cart/cart-item';
import { CartService } from 'src/app/shared/services/cart.service';

enum State {
  Initial,
  UpdatingStartDate,
  ErrorUpdatingStartDate,
  Closed
}

@Component({
  selector: 'fly-cart-item-change-start-date-modal',
  templateUrl: './cart-item-change-start-date-modal.component.html',
  styleUrl: './cart-item-change-start-date-modal.component.scss',
  standalone: false
})
export class CartItemChangeStartDateModalComponent implements OnInit {
  @ViewChild(ModalComponent) modal: ModalComponent;
  @Input({ required: true }) item: CartItem;
  @Input({ required: true }) itemType: 'avdb' | 'avdbBundle' | 'gp' = 'avdb';
  private readonly cartService = inject(CartService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly siteConfigService = inject(SiteConfigService);
  private readonly DEFAULT_MAXIMUM_DAYS_FROM_NOW = 90;

  readonly State = State;
  currentState: State = State.Closed;

  minDate: string = '';
  maxDate: string = '';
  selectedDate = new FormControl('');
  selectedDateOption = new FormControl('1');

  buttons: ModalButton[] = [];
  initialButtons: ModalButton[] = [];

  radioName = 'date-selection';

  ngOnInit(): void {
    this.siteConfigService
      .getSiteConfig()
      .pipe(
        map((siteConfig) => siteConfig.purchasingMaximumStartDate ?? this.DEFAULT_MAXIMUM_DAYS_FROM_NOW),
        catchError(() => of(this.DEFAULT_MAXIMUM_DAYS_FROM_NOW)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((maximumDaysFromNow: number) => {
        const now = new Date();
        this.minDate = this.getIsoDateString(addDays(now, 1));
        this.maxDate = this.getIsoDateString(addDays(now, maximumDaysFromNow));
      });

    if (this.item.futureStartDate == null) {
      this.selectedDateOption.setValue('1');
      this.selectedDate.disable();
    } else {
      this.selectedDate.enable();
      this.selectedDateOption.setValue('2');
      this.selectedDate.setValue(this.item.futureStartDate.split('T')[0]);
    }

    this.initialButtons = [
      {
        text: 'Cancel',
        action: 'close'
      },
      {
        text: 'Save Start Date',
        primary: true,
        action: this.modalConfirm
      }
    ];

    this.buttons = this.initialButtons;
    this.radioName = `${this.item.id}-date-selection`;
  }

  open(): void {
    this.currentState = State.Initial;
    this.modal.open();
  }

  close = (): void => {
    this.currentState = State.Closed;
    this.modal.close();
  };

  onSelectionChange(): void {
    if (this.selectedDateOption.value === '2') {
      this.selectedDate.enable();
    } else {
      this.selectedDate.disable();
    }
  }

  modalConfirm = (): void => {
    let isoSelectedDate = null;
    if (this.selectedDateOption.value !== '1' && this.selectedDate.value != null) {
      const [year, month, day] = this.selectedDate.value.split('-').map(Number);
      const selectedDateWithTimeZone = new Date();
      selectedDateWithTimeZone.setFullYear(year, month - 1, day);
      isoSelectedDate = selectedDateWithTimeZone.toISOString();
    }

    this.currentState = State.UpdatingStartDate;
    this.buttons = [];

    let updateRequest$ = null;
    if (this.itemType === 'avdb') {
      updateRequest$ = this.cartService.updateAvdbStartDate(this.item.id, isoSelectedDate);
    } else if (this.itemType === 'avdbBundle') {
      updateRequest$ = this.cartService.updateAvdbBundleStartDate(this.item.id, isoSelectedDate);
    } else {
      updateRequest$ = this.cartService.updateGpStartDate(this.item.id, isoSelectedDate);
    }

    updateRequest$
      .pipe(
        catchError((error) => {
          console.error(error);
          this.currentState = State.ErrorUpdatingStartDate;
          this.buttons = this.initialButtons;
          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (response != null) {
          this.item.futureStartDate = isoSelectedDate;
          this.buttons = this.initialButtons;
          this.currentState = State.Initial;
          this.modal.close();
        }
      });
  };

  getIsoDateString(date: Date): string {
    return date.toISOString().split('T')[0];
  }
}
