import { Component, Input, OnInit } from '@angular/core';
import { SiteConfigService } from '@garmin-avcloud/avcloud-fly-web-common/config';
import { AvdbPricingProduct } from 'src/app/shared/models/avdb-pricing/avdb-pricing-product.model';

/**
 * This component is to be used only for AvdbProducts (one annual and one monthly max) that share a single series.
 */
@Component({
  selector: 'fly-coverage-details-avdb-single-series',
  templateUrl: './coverage-details-avdb-single-series.component.html',
  styleUrl: './coverage-details-avdb-single-series.component.scss',
  standalone: false
})
export class CoverageDetailsAvdbSingleSeriesComponent implements OnInit {
  // The array of products should only contain a max of two products, one annual and one monthly
  // The products are expected to share a series with the only difference being the price, and part key
  @Input({ required: true }) avdbPricingProducts: AvdbPricingProduct[];

  isLoading: boolean = true;
  coverageMapUrl: string | null = null;

  constructor(readonly siteConfigService: SiteConfigService) {}

  ngOnInit(): void {
    if (
      this.avdbPricingProducts.some(
        (product) => product.series.length > 1 || product.series[0].id !== this.avdbPricingProducts[0].series[0].id
      )
    ) {
      console.warn(
        'It appears that one or more products has more than one series, or the series for the products do not match. Update the modal component used accordingly.'
      );
    }

    this.siteConfigService.getSiteConfig().subscribe((config) => {
      if (
        this.avdbPricingProducts[0].series[0].region.coverageMapFilename != null &&
        this.avdbPricingProducts[0].series[0].region.coverageMapFilename.length > 0
      ) {
        this.coverageMapUrl = `${config.cdnUrl}/img/avdb_type_regions/${this.avdbPricingProducts[0].series[0].region.coverageMapFilename}`;
      }
      this.isLoading = false;
    });
  }
}
