import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartItemType, SkuProduct } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CartItemMode } from '../../../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../../../shared/enums/observable-status-enum';
import { CartItem } from '../../../../../../shared/models/cart/cart-item';
import { Cart } from '../../../../../../shared/models/cart/cart.model';
import { EnablementCartItem } from '../../../../../../shared/models/cart/enablement-cart-item';
import { EnablementPricingResponse } from '../../../../../../shared/models/enablement/enablement-pricing-response.model';
import { Enablement } from '../../../../../../shared/models/enablement/enablement.model';
import { Money } from '../../../../../../shared/models/money.model';
import { CartService } from '../../../../../../shared/services/cart.service';
import { EnablementPricingService } from '../../../../../../shared/services/enablement-pricing.service';
import { LinksService } from '../../../../../../shared/services/links.service';

@Component({
  selector: 'fly-cart-item-enablement',
  templateUrl: './cart-item-enablement.component.html',
  standalone: false
})
export class CartItemEnablementComponent implements OnInit {
  @Input() cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  @Input() skuProduct?: SkuProduct;
  @Input() item: EnablementCartItem;
  @Input() enablementPricingResponseArray: EnablementPricingResponse[];
  @Input() price?: Money;
  @Input() hasError: boolean = false;
  @Input() flyLegacyDeviceDetailsUrl: string;
  @Input() removeCartItem$: Observable<Cart>;
  @Output() readonly priceByCartItemId = new EventEmitter<{
    cartItemId: string;
    price: { price?: Money; error?: string };
  }>();
  @Output() readonly removeCartItem: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly removingCartItem: EventEmitter<boolean> = new EventEmitter();
  readonly ObservableStatus = ObservableStatus;
  enablementPricingResponseArray$: Observable<EnablementPricingResponse[]>;
  cartItemType: CartItemType = CartItemType.ENABLEMENT;
  asyncData$: Observable<AsyncData>;

  constructor(
    readonly enablementPricingService: EnablementPricingService,
    readonly cartService: CartService,
    readonly linksService: LinksService
  ) {}

  ngOnInit(): void {
    if (this.skuProduct == null) {
      this.hasError = true;
      return;
    }

    this.flyLegacyDeviceDetailsUrl = this.linksService.getFlyLegacyDeviceDetailsUrl(this.item.device.id);

    this.removeCartItem$ = this.item == null ? EMPTY : this.cartService.removeEnablementFromCurrentCart(this.item.id);

    this.enablementPricingResponseArray$ = ((): Observable<EnablementPricingResponse[]> => {
      if (this.item == null || this.hasError) {
        return EMPTY;
      } else if (this.cartItemMode !== CartItemMode.CONFIRMATION || this.enablementPricingResponseArray == null) {
        return this.enablementPricingService.getEnablementPricingForDevices([this.item.device?.id]);
      } else {
        return of(this.enablementPricingResponseArray);
      }
    })();

    this.asyncData$ = this.enablementPricingResponseArray$.pipe(
      map((enablementPricingResponseArray) => {
        const enablement = this.findEnablement(enablementPricingResponseArray);
        const price = this.findPrice(enablement);
        return {
          enablement,
          price
        };
      }),
      tap((asyncData: AsyncData) => {
        this.priceByCartItemId.emit({ cartItemId: this.item.id, price: { price: asyncData.price } });
      }),
      catchError((error: any) => {
        this.hasError = true;
        const priceWithError: Money = {
          value: null,
          currencyCode: null,
          currencySymbol: null,
          fractionalDigits: null,
          formattedValue: null
        };
        this.priceByCartItemId.emit({
          cartItemId: this.item.id,
          price: { price: priceWithError, error: error.message }
        });
        return throwError(() => error);
      })
    );
  }

  findEnablement(enablementPricingResponseArray: EnablementPricingResponse[]): Enablement | undefined {
    if (this.cartItemMode === CartItemMode.CONFIRMATION) {
      return undefined;
    }
    return enablementPricingResponseArray
      .find((enablementPricingResponse) => {
        return enablementPricingResponse.enablements.some((enablement) => {
          return enablement.partKey === (this.skuProduct?.sku ?? this.item?.product.partKey);
        });
      })
      ?.enablements.find((enablement) => {
        return enablement.partKey === (this.skuProduct?.sku ?? this.item?.product.partKey);
      });
  }

  performAfterPurchaseAction = (cartItem: CartItem): void => {
    window.location.href = this.linksService.getFlyLegacyDeviceDetailsUrl((cartItem as EnablementCartItem).device.id);
  };

  private findPrice(enablement?: Enablement): Money | undefined {
    switch (this.cartItemMode) {
      case CartItemMode.CONFIRMATION:
        return this.item.orderPrice;
      case CartItemMode.CHECKOUT:
        return enablement?.price;
      case CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION:
        return enablement?.price;
      default:
        return enablement?.price;
    }
  }
}

interface AsyncData {
  enablement?: Enablement;
  price?: Money;
}
