import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartItemType, SkuProduct } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CartItemMode } from '../../../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../../../shared/enums/observable-status-enum';
import { CartItem } from '../../../../../../shared/models/cart/cart-item';
import { EnablementCartItem } from '../../../../../../shared/models/cart/enablement-cart-item';
import { EnablementPricingResponse } from '../../../../../../shared/models/enablement/enablement-pricing-response.model';
import { Money } from '../../../../../../shared/models/money.model';
import { EnablementPricingService } from '../../../../../../shared/services/enablement-pricing.service';

@Component({
  selector: 'fly-cart-items-enablements',
  templateUrl: './cart-items-enablements.component.html',
  standalone: false
})
export class CartItemsEnablementsComponent implements OnInit {
  @Input() cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  @Input() enablementsCartItems: EnablementCartItem[];
  @Input() skuProductsMap: Map<string, SkuProduct>;
  @Input() deviceId?: string;
  @Input() price: Money;
  @Output() readonly priceByCartItemId = new EventEmitter<{
    cartItemId: string;
    price: { price?: Money; error?: string };
  }>();
  @Output() readonly removeCartItem: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly removingCartItem: EventEmitter<boolean> = new EventEmitter();
  readonly ObservableStatus = ObservableStatus;
  enablementPricingResponseArray$: Observable<EnablementPricingResponse[] | null>;
  hasError: boolean = false;
  cartItemType: CartItemType = CartItemType.ENABLEMENT;

  constructor(readonly enablementPricingService: EnablementPricingService) {}

  ngOnInit(): void {
    this.enablementPricingResponseArray$ = this.getEnablementPricing();
  }

  private getEnablementPricing(): Observable<EnablementPricingResponse[] | null> {
    if (this.deviceId != null) {
      return this.enablementPricingService
        .getEnablementPricingForDevices([parseInt(this.deviceId, 10)])
        .pipe(catchError(this.avdbPricingErrorHandler));
    } else {
      return of(null).pipe(catchError(this.avdbPricingErrorHandler));
    }
  }

  private avdbPricingErrorHandler(error: any): Observable<never> {
    this.hasError = true;
    const priceWithError: Money = {
      value: null,
      currencyCode: null,
      currencySymbol: null,
      fractionalDigits: null,
      formattedValue: null
    };
    this.enablementsCartItems.forEach((item) => {
      this.priceByCartItemId.emit({ cartItemId: item.id, price: { price: priceWithError, error: error.message } });
    });
    return throwError(() => error);
  }
}
