import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AvdbPricingBundleType,
  SkuProduct,
  CartItemType,
  YarmouthSubscription
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CartItemMode } from '../../../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../../../shared/enums/observable-status-enum';
import { AvdbPricingAircraftBundle } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-aircraft-bundle.model';
import { AvdbPricingBundle } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-bundle.model';
import { AvdbPricingDeviceBundle } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-device-bundle.model';
import { AvdbPricingResponse } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-response.model';
import { AvdbBundleCartItem } from '../../../../../../shared/models/cart/avdb-bundle-cart-item';
import { CartItem } from '../../../../../../shared/models/cart/cart-item';
import { Cart } from '../../../../../../shared/models/cart/cart.model';
import { CategorizedCart } from '../../../../../../shared/models/cart/categorized-cart.model';
import { Money } from '../../../../../../shared/models/money.model';
import { LegacySubscriptionExpiringResponse } from '../../../../../../shared/models/subscriptions/legacy-subscription-expiring-response.model';
import { CartItemUtilityService } from '../../../../../../shared/services/cart-item-utility.service';
import { CartService } from '../../../../../../shared/services/cart.service';
import { DatabasePricingService } from '../../../../../../shared/services/database-pricing.service';
import { LinksService } from '../../../../../../shared/services/links.service';

@Component({
  selector: 'fly-cart-item-avdb-bundle',
  templateUrl: './cart-item-avdb-bundle.component.html',
  styleUrl: './cart-item-avdb-bundle.component.scss',
  standalone: false
})
export class CartItemAvdbBundleComponent implements OnInit {
  @Input() cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  @Input() item?: AvdbBundleCartItem;
  @Input() legacySubscriptionExpiringResponse: LegacySubscriptionExpiringResponse;
  @Input() skuProduct?: SkuProduct;
  @Input() avdbPricingResponse: AvdbPricingResponse;
  @Input() currentCartCategorized: CategorizedCart;
  @Output() readonly priceByCartItemId = new EventEmitter<{
    cartItemId: string;
    price: { price?: Money; error?: string };
  }>();
  @Output() readonly removeCartItem: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly removingCartItem: EventEmitter<boolean> = new EventEmitter();
  @Input() hasError: boolean = false;
  @Input() removeCartItem$: Observable<Cart>;
  @Input() renewalSubscriptionsMap: Map<string, YarmouthSubscription>;
  readonly AvdbPricingBundleTypes = AvdbPricingBundleType;
  readonly ObservableStatus = ObservableStatus;
  bundleType: AvdbPricingBundleType | null = null;
  avdbPricingResponse$: Observable<AvdbPricingResponse>;
  cartItemType: CartItemType = CartItemType.DATABASE_BUNDLE;
  asyncData$: Observable<AsyncData>;
  deviceId: number;
  aircraftTailNumber: string;
  sku: string;
  flyLegacyAircraftDetailsUrl: string | null = null;
  flyLegacyDeviceDetailsUrl: string | null = null;
  presentInCurrentCart: boolean;
  expandCollapsedBundleDetails: boolean = false;
  newExpiration: Date | null = null;
  subscriptionsEarlyRenewalEnabled = false;
  futureStartEnabled = false;
  protected readonly CartItemMode = CartItemMode;
  protected readonly AvdbPricingBundleType = AvdbPricingBundleType;
  private readonly databasePricingService = inject(DatabasePricingService);
  private readonly cartService = inject(CartService);
  private readonly linksService = inject(LinksService);
  private readonly cartItemUtilityService = inject(CartItemUtilityService);
  private readonly featuresService = inject(FeaturesService);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.skuProduct == null) {
      this.hasError = true;
      return;
    }

    this.deviceId = this.legacySubscriptionExpiringResponse?.devicePk ?? this.item?.device?.id;
    this.aircraftTailNumber = this.legacySubscriptionExpiringResponse?.tailNumber ?? this.item?.aircraft?.id;
    this.sku = this.skuProduct.sku;
    this.bundleType = this.skuProduct.bundleType ?? null;
    this.flyLegacyAircraftDetailsUrl =
      this.bundleType === AvdbPricingBundleType.PILOTPAK || this.bundleType === AvdbPricingBundleType.ONEPAK
        ? this.linksService.getFlyLegacyAircraftDetailsUrl(this.aircraftTailNumber)
        : null;
    this.flyLegacyDeviceDetailsUrl =
      this.bundleType === AvdbPricingBundleType.DEVICE
        ? this.linksService.getFlyLegacyDeviceDetailsUrl(this.deviceId)
        : null;
    if (
      this.currentCartCategorized != null &&
      (this.bundleType === AvdbPricingBundleType.PILOTPAK || this.bundleType === AvdbPricingBundleType.ONEPAK)
    ) {
      this.presentInCurrentCart =
        this.currentCartCategorized.aircraftBundlesCart
          .get(this.aircraftTailNumber)
          ?.some((item) => item.bundle.partKey === this.sku) ?? false;
    } else if (this.currentCartCategorized != null && this.bundleType === AvdbPricingBundleType.DEVICE) {
      this.presentInCurrentCart =
        this.currentCartCategorized.deviceBundlesCart
          .get(String(this.deviceId))
          ?.some((item) => item.bundle.partKey === this.sku) ?? false;
    }

    this.removeCartItem$ = this.item == null ? EMPTY : this.cartService.removeAvdbBundleFromCurrentCart(this.item.id);

    this.avdbPricingResponse$ = ((): Observable<AvdbPricingResponse> => {
      if ((this.item == null && this.legacySubscriptionExpiringResponse == null) || this.hasError) {
        return EMPTY;
      } else if (this.cartItemMode !== CartItemMode.CONFIRMATION && this.avdbPricingResponse == null) {
        switch (this.bundleType) {
          case AvdbPricingBundleType.PILOTPAK:
          case AvdbPricingBundleType.ONEPAK:
            return this.databasePricingService.getAvdbPricingForAircraft(this.aircraftTailNumber, undefined, true);
          case AvdbPricingBundleType.DEVICE:
            return this.databasePricingService.getAvdbPricingForDevices([this.deviceId], undefined, true);
          default:
            return EMPTY;
        }
      } else {
        return of(this.avdbPricingResponse);
      }
    })();

    this.asyncData$ = this.avdbPricingResponse$.pipe(
      map((avdbPricingResponse) => {
        const avdbPricingBundle = this.findBundle(avdbPricingResponse);
        const aircraftBundle = this.isAircraftBundle() ? (avdbPricingBundle as AvdbPricingAircraftBundle) : undefined;
        const deviceBundle = this.isDeviceBundle() ? (avdbPricingBundle as AvdbPricingDeviceBundle) : undefined;

        const price = this.findPrice(avdbPricingBundle);

        return {
          aircraftBundle,
          deviceBundle,
          price
        };
      }),
      tap((asyncData: AsyncData) => {
        this.priceByCartItemId.emit({ cartItemId: this.item?.id ?? '', price: { price: asyncData.price } });
      }),
      catchError((error: any) => {
        console.error(error);
        this.hasError = true;
        const priceWithError: Money = {
          value: null,
          currencyCode: null,
          currencySymbol: null,
          fractionalDigits: null,
          formattedValue: null
        };
        this.priceByCartItemId.emit({
          cartItemId: this.item?.id ?? '',
          price: { price: priceWithError, error: error.message }
        });
        return throwError(() => error);
      })
    );

    if (this.item?.existingSubscriptionUuid != null) {
      this.featuresService
        .isFeatureActive(FeatureFlyGarmin.SUBSCRIPTIONS_EARLY_RENEWAL)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((enabled) => {
          this.subscriptionsEarlyRenewalEnabled = enabled;
          if (enabled) {
            if (this.skuProduct != null && this.item != null && this.cartItemMode === CartItemMode.CHECKOUT) {
              try {
                this.newExpiration = this.cartItemUtilityService.calculateNewRenewalDate(
                  this.item,
                  this.renewalSubscriptionsMap,
                  this.skuProduct
                );
              } catch (error) {
                this.hasError = true;
                throw error;
              }
            }
          } else {
            this.hasError = true;
            throw new Error();
          }
        });
    }

    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.ENABLE_FUTURE_START_DATE)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => (this.futureStartEnabled = enabled));
  }

  findBundle(
    avdbPricingResponse: AvdbPricingResponse
  ): AvdbPricingAircraftBundle | AvdbPricingDeviceBundle | undefined {
    if (this.cartItemMode === CartItemMode.CONFIRMATION) {
      return undefined;
    }

    switch (this.bundleType) {
      case AvdbPricingBundleType.PILOTPAK:
      case AvdbPricingBundleType.ONEPAK: {
        return this.findAircraftBundle(avdbPricingResponse);
      }
      case AvdbPricingBundleType.DEVICE: {
        return this.findDeviceBundle(avdbPricingResponse);
      }
      default: {
        return undefined;
      }
    }
  }

  performAfterPurchaseAction = (cartItem: CartItem): void => {
    switch (this.bundleType) {
      case AvdbPricingBundleType.ONEPAK:
      case AvdbPricingBundleType.PILOTPAK: {
        const aircraftId = (cartItem as AvdbBundleCartItem).aircraft?.id;

        if (aircraftId != null) {
          window.location.href = this.linksService.getFlyLegacyAircraftDetailsUrl(aircraftId);
        }

        break;
      }
      case AvdbPricingBundleType.DEVICE: {
        const deviceId = (cartItem as AvdbBundleCartItem).device?.id;

        if (deviceId != null) {
          window.location.href = this.linksService.getFlyLegacyDeviceDetailsUrl(deviceId);
        }

        break;
      }
      default:
    }
  };

  private findPrice(avdbPricingBundle?: AvdbPricingBundle): Money | undefined {
    switch (this.cartItemMode) {
      case CartItemMode.CONFIRMATION:
        return this.item?.orderPrice;
      case CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION:
        return avdbPricingBundle?.price;
      case CartItemMode.CHECKOUT:
        return avdbPricingBundle?.price;
      default:
        return avdbPricingBundle?.price;
    }
  }

  isAircraftBundle(): boolean {
    return (
      this.skuProduct?.bundleType === AvdbPricingBundleType.ONEPAK ||
      this.skuProduct?.bundleType === AvdbPricingBundleType.PILOTPAK
    );
  }

  isDeviceBundle(): boolean {
    return this.skuProduct?.bundleType === AvdbPricingBundleType.DEVICE;
  }

  private findAircraftBundle(avdbPricingResponse: AvdbPricingResponse): AvdbPricingAircraftBundle | undefined {
    return avdbPricingResponse.aircraftBundles.find((avdbPricingAircraftBundle) => {
      return avdbPricingAircraftBundle.partKey === this.sku;
    });
  }

  private findDeviceBundle(avdbPricingResponse: AvdbPricingResponse): AvdbPricingDeviceBundle | undefined {
    return avdbPricingResponse.devices
      .find((device) => {
        return device.id === this.deviceId;
      })
      ?.bundles?.find((bundle) => {
        return bundle.partKey === this.sku;
      });
  }
}

interface AsyncData {
  aircraftBundle?: AvdbPricingAircraftBundle;
  deviceBundle?: AvdbPricingDeviceBundle;
  price?: Money;
}
