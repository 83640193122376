<ng-template #header>
  <h5 class="cart-item-type">
    {{ cartItemTypeDisplayName[cartItemType] }}
  </h5>

  @switch (true) {
    @case (headerText == null) {
      <ng-content select="[header]"></ng-content>
    }
    @case (headerText != null) {
      <div>
        <h2 class="cart-item-name">
          {{ headerText }}
        </h2>
      </div>
    }
  }
</ng-template>

<ng-template #body>
  <div class="card-body">
    <ng-content select="[body]"></ng-content>
  </div>
</ng-template>

<ng-template #collapsedDetails>
  <g-button
    (click)="expandOrCollapseDetails()"
    [theme]="'light'"
    [size]="'medium'"
    [flat]="true"
    [flatTheme]="'primary'"
    class="collapsed-details-button">
    <g-flex class="collapsed-details">
      {{
        expandCollapsedDetails
          ? 'Hide ' + kindOfCollapsedDetails + ' Details'
          : 'View ' + kindOfCollapsedDetails + ' Details'
      }}
      <img
        alt="{{
          expandCollapsedDetails
            ? 'Hide ' + kindOfCollapsedDetails + ' Details'
            : 'View ' + kindOfCollapsedDetails + ' Details'
        }}"
        [ngClass]="{ 'rotate-arrow': expandCollapsedDetails }"
        class="arrow"
        src="assets/garmin-regalia-icons/arrow-down/icon.svg" />
    </g-flex>
  </g-button>
  <ng-content select="[collapsedDetails]"></ng-content>
</ng-template>

<ng-template #removeCartItemFailedMessage>
  @if (removeFailed) {
    <g-error class="error-removing">
      An error occurred while removing this item. Please try again, or contact
      <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.
    </g-error>
  }
</ng-template>

<ng-template #removeCartItemButton>
  @if (removing) {
    <g-loader
      class="loader"
      type="dark">
    </g-loader>
  }
  @if (!removing) {
    <button
      (click)="removeCartItem()"
      class="remove-cart-button">
      <img
        alt="remove"
        src="../../../../../../assets/garmin-regalia-icons/cancel/icon.svg" />
    </button>
  }
</ng-template>

@switch (currentState) {
  @case (State.Loading) {
    <g-flex class="loading-container">
      <g-flex class="info-container">
        <div class="skeleton-1">
          <g-skeleton> </g-skeleton>
        </div>
        <div class="skeleton-2">
          <g-skeleton> </g-skeleton>
        </div>
        <div class="skeleton-3">
          <g-skeleton> </g-skeleton>
        </div>
        <div class="skeleton-4">
          <g-skeleton> </g-skeleton>
        </div>
      </g-flex>
      <g-flex class="price-container">
        @if (cartItemMode === CartItemMode.CHECKOUT) {
          <div class="price-skeleton-1">
            <g-skeleton> </g-skeleton>
          </div>
        }
        <div class="skeleton-margin">
          <div class="price-skeleton-2">
            <g-skeleton> </g-skeleton>
          </div>
        </div>
      </g-flex>
    </g-flex>
  }

  @case (State.Loaded) {
    <g-flex class="loaded-container">
      @switch (cartItemMode) {
        @case (CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION) {
          <g-flex class="generic-sub-container">
            <g-flex class="add-expired-sub-button-container">
              <g-flex class="header-body-container">
                <ng-container [ngTemplateOutlet]="header"></ng-container>
                <ng-container [ngTemplateOutlet]="body"></ng-container>
              </g-flex>
              <fly-add-to-cart-button
                [presentInCurrentCart]="presentInCurrentCart"
                [itemToAddToCurrentCart]="itemForAddingToCurrentCart"
                (addToCurrentCartFailed)="addToCurrentCartFailed = $event">
              </fly-add-to-cart-button>
            </g-flex>
            @if (addToCurrentCartFailed) {
              <g-error class="error">
                An error occurred while adding this item. Please try again, or contact
                <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.
              </g-error>
            }
            @if (hasCollapsedDetails) {
              <ng-container [ngTemplateOutlet]="collapsedDetails"> </ng-container>
            }
          </g-flex>
        }

        @case (CartItemMode.CONFIRMATION) {
          <g-flex class="generic-sub-container">
            <g-flex class="confirmation-container">
              <g-flex class="header-body-container">
                <ng-container [ngTemplateOutlet]="header"></ng-container>
                <ng-container [ngTemplateOutlet]="body"></ng-container>
              </g-flex>
              <g-flex class="confirmation-price">
                @if (price != null) {
                  <g-price [originalPrice]="price | flyMoney"></g-price>
                }
              </g-flex>
            </g-flex>
          </g-flex>
        }

        @case (CartItemMode.CHECKOUT) {
          <g-flex class="generic-sub-container">
            <g-flex class="checkout-container">
              <g-flex class="header-body-container">
                <ng-container [ngTemplateOutlet]="removeCartItemFailedMessage"></ng-container>
                <ng-container [ngTemplateOutlet]="header"></ng-container>
                <ng-container [ngTemplateOutlet]="body"></ng-container>
                @if (price != null) {
                  <g-price
                    class="checkout-price"
                    [originalPrice]="price | flyMoney">
                  </g-price>
                }
              </g-flex>
              <g-flex class="remove-cart-item-button-container">
                <ng-container [ngTemplateOutlet]="removeCartItemButton"></ng-container>
                @if (price != null) {
                  <g-price
                    class="remove-price"
                    [originalPrice]="price.formattedValue">
                  </g-price>
                }
              </g-flex>
            </g-flex>
            @if (cartItemValidationFailed) {
              <g-error class="error">
                <p [innerHTML]="yarmouthValidationErrorMessage"></p>
              </g-error>
            }
            @if (hasCollapsedDetails) {
              <ng-container [ngTemplateOutlet]="collapsedDetails"> </ng-container>
            }
          </g-flex>
        }
      }
    </g-flex>
  }

  @case (State.Error) {
    <g-flex class="error-container">
      <g-flex class="header-body-container">
        <ng-container [ngTemplateOutlet]="removeCartItemFailedMessage"></ng-container>
        <ng-container [ngTemplateOutlet]="header"></ng-container>
        <g-error class="error">
          <p [innerHTML]="errorMessage"> </p>
        </g-error>
      </g-flex>
      <g-flex class="remove-cart-item-button-container">
        @if (cartItemMode === CartItemMode.CHECKOUT) {
          <ng-container [ngTemplateOutlet]="removeCartItemButton"> </ng-container>
        }
      </g-flex>
    </g-flex>
  }
}
