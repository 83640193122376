import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvdbPricingBundleType, SkuProduct, YarmouthSubscription } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CartItemMode } from '../../../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../../../shared/enums/observable-status-enum';
import { AvdbPricingResponse } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-response.model';
import { AvdbBundleCartItem } from '../../../../../../shared/models/cart/avdb-bundle-cart-item';
import { CartItem } from '../../../../../../shared/models/cart/cart-item';
import { CategorizedCart } from '../../../../../../shared/models/cart/categorized-cart.model';
import { Money } from '../../../../../../shared/models/money.model';
import { LegacySubscriptionExpiringResponse } from '../../../../../../shared/models/subscriptions/legacy-subscription-expiring-response.model';
import { DatabasePricingService } from '../../../../../../shared/services/database-pricing.service';

@Component({
  selector: 'fly-cart-items-avdb-bundles',
  templateUrl: './cart-items-avdb-bundles.component.html',
  standalone: false
})
export class CartItemsAvdbBundlesComponent implements OnInit {
  @Input() cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  @Input() aircraftId?: string;
  @Input() deviceId?: string;
  @Input() price: Money;
  @Input() skuProductsMap: Map<string, SkuProduct>;
  @Input() avdbBundlesCartItems: AvdbBundleCartItem[];
  @Input() legacySubscriptionExpiringResponses: LegacySubscriptionExpiringResponse[];
  @Input() bundleType: AvdbPricingBundleType;
  @Input() currentCartCategorized: CategorizedCart;
  @Input() renewalSubscriptionsMap: Map<string, YarmouthSubscription>;
  @Output() readonly priceByCartItemId = new EventEmitter<{
    cartItemId: string;
    price: { price?: Money; error?: string };
  }>();
  @Output() readonly removeCartItem: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly removingCartItem: EventEmitter<boolean> = new EventEmitter();
  readonly ObservableStatus = ObservableStatus;
  avdbPricingResponse$: Observable<AvdbPricingResponse | null>;
  hasError: boolean = false;
  protected readonly CartItemMode = CartItemMode;

  constructor(readonly databasePricingService: DatabasePricingService) {}

  ngOnInit(): void {
    this.avdbPricingResponse$ = this.getAvdbPricing();
  }

  private getAvdbPricing(): Observable<AvdbPricingResponse | null> {
    if (this.aircraftId != null) {
      return this.databasePricingService
        .getAvdbPricingForAircraft(this.aircraftId, undefined, true)
        .pipe(catchError(this.avdbPricingErrorHandler));
    } else if (this.deviceId != null) {
      return this.databasePricingService
        .getAvdbPricingForDevices([parseInt(this.deviceId, 10)], undefined, true)
        .pipe(catchError(this.avdbPricingErrorHandler));
    } else {
      return of(null);
    }
  }

  private avdbPricingErrorHandler(error: any): Observable<never> {
    this.hasError = true;
    const priceWithError: Money = {
      value: null,
      currencyCode: null,
      currencySymbol: null,
      fractionalDigits: null,
      formattedValue: null
    };
    this.avdbBundlesCartItems.forEach((item) => {
      this.priceByCartItemId.emit({ cartItemId: item.id, price: { price: priceWithError, error: error.message } });
    });
    return throwError(() => error);
  }
}
