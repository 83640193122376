import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CartItemType, SkuProduct, YarmouthSubscription } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CartItemMode } from '../../../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../../../shared/enums/observable-status-enum';
import { AvdbPricingProductType } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-product-type.model';
import { AvdbPricingProduct } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-product.model';
import { AvdbPricingResponse } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-response.model';
import { AvdbCartItem } from '../../../../../../shared/models/cart/avdb-cart-item.model';
import { CartItem } from '../../../../../../shared/models/cart/cart-item';
import { Cart } from '../../../../../../shared/models/cart/cart.model';
import { CategorizedCart } from '../../../../../../shared/models/cart/categorized-cart.model';
import { Money } from '../../../../../../shared/models/money.model';
import { LegacySubscriptionExpiringResponse } from '../../../../../../shared/models/subscriptions/legacy-subscription-expiring-response.model';
import { CartItemUtilityService } from '../../../../../../shared/services/cart-item-utility.service';
import { CartService } from '../../../../../../shared/services/cart.service';
import { DatabasePricingService } from '../../../../../../shared/services/database-pricing.service';
import { LinksService } from '../../../../../../shared/services/links.service';

@Component({
  selector: 'fly-cart-item-avdb',
  templateUrl: './cart-item-avdb.component.html',
  styleUrl: './cart-item-avdb.component.scss',
  standalone: false
})
export class CartItemAvdbComponent implements OnInit {
  @Input() cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  @Input() item: AvdbCartItem;
  @Input() avdbPricingResponse: AvdbPricingResponse;
  @Input() hasError: boolean = false;
  @Input() skuProduct?: SkuProduct;
  @Input() legacySubscriptionExpiringResponse: LegacySubscriptionExpiringResponse;
  @Input() presentInCurrentCart: boolean;
  @Input() currentCartCategorized: CategorizedCart;
  @Input() renewalSubscriptionsMap: Map<string, YarmouthSubscription>;
  @Output() readonly priceByCartItemId = new EventEmitter<{
    cartItemId: string;
    price: { price?: Money; error?: string };
  }>();
  @Output() readonly removeCartItem: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly removingCartItem: EventEmitter<boolean> = new EventEmitter();
  readonly ObservableStatus = ObservableStatus;
  expandCollapsedCoverageDetails: boolean = false;
  avdbPricingResponse$: Observable<AvdbPricingResponse>;
  containsMultipleDatabases: boolean;
  cartItemType: CartItemType = CartItemType.DATABASE;
  removeCartItem$: Observable<Cart>;
  asyncData$: Observable<AsyncData>;
  deviceId: number;
  sku: string;
  duration: string;
  flyLegacyDeviceDetailsUrl: string;
  protected readonly CartItemMode = CartItemMode;
  newExpiration: Date | null = null;
  subscriptionsEarlyRenewalEnabled = false;
  futureStartEnabled = false;

  private readonly databasePricingService = inject(DatabasePricingService);
  private readonly cartService = inject(CartService);
  private readonly linksService = inject(LinksService);
  private readonly cartItemUtilityService = inject(CartItemUtilityService);
  private readonly featuresService = inject(FeaturesService);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.skuProduct == null) {
      this.hasError = true;
      return;
    }

    this.deviceId = this.legacySubscriptionExpiringResponse?.devicePk ?? this.item?.device?.id;
    this.sku = this.skuProduct?.sku;
    this.duration = this.skuProduct?.duration;
    this.flyLegacyDeviceDetailsUrl = this.linksService.getFlyLegacyDeviceDetailsUrl(this.deviceId);
    this.presentInCurrentCart = this.currentCartCategorized?.avdbCart.some((item) => {
      return item.product.partKey === this.sku && item.device.id === this.deviceId;
    });
    this.removeCartItem$ = this.item == null ? EMPTY : this.cartService.removeAvdbFromCurrentCart(this.item?.id);

    this.containsMultipleDatabases = this.skuProduct.subProducts != null;

    this.avdbPricingResponse$ = ((): Observable<AvdbPricingResponse> => {
      if ((this.item == null && this.legacySubscriptionExpiringResponse == null) || this.hasError) {
        return EMPTY;
      } else if (this.cartItemMode !== CartItemMode.CONFIRMATION || this.avdbPricingResponse == null) {
        return this.databasePricingService.getAvdbPricingForDevices([this.deviceId], this.sku);
      } else {
        return of(this.avdbPricingResponse);
      }
    })();

    this.asyncData$ = this.avdbPricingResponse$.pipe(
      map((avdbPricingResponse) => {
        const avdbPricingProductType = this.findAvdbPricingProductType(avdbPricingResponse);
        const avdbPricingProduct = this.findProduct(avdbPricingProductType);
        const price = this.getPrice(avdbPricingProduct);
        return {
          avdbPricingProductType,
          price
        };
      }),
      tap((asyncData: AsyncData) => {
        this.priceByCartItemId.emit({ cartItemId: this.item?.id, price: { price: asyncData.price } });
      }),
      catchError((error: any) => {
        this.hasError = true;
        const priceWithError: Money = {
          value: null,
          currencyCode: null,
          currencySymbol: null,
          fractionalDigits: null,
          formattedValue: null
        };
        this.priceByCartItemId.emit({
          cartItemId: this.item?.id,
          price: { price: priceWithError, error: error.message }
        });
        return throwError(() => error);
      })
    );

    if (this.item.existingSubscriptionUuid != null) {
      this.featuresService
        .isFeatureActive(FeatureFlyGarmin.SUBSCRIPTIONS_EARLY_RENEWAL)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((enabled) => {
          this.subscriptionsEarlyRenewalEnabled = enabled;
          if (enabled) {
            if (this.skuProduct != null && this.cartItemMode === CartItemMode.CHECKOUT) {
              try {
                this.newExpiration = this.cartItemUtilityService.calculateNewRenewalDate(
                  this.item,
                  this.renewalSubscriptionsMap,
                  this.skuProduct
                );
              } catch (error) {
                this.hasError = true;
                throw error;
              }
            }
          } else {
            this.hasError = true;
            throw new Error();
          }
        });
    }

    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.ENABLE_FUTURE_START_DATE)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => (this.futureStartEnabled = enabled));
  }

  performAfterPurchaseAction = (cartItem: CartItem): void => {
    window.location.href = this.linksService.getFlyLegacyDeviceDetailsUrl((cartItem as AvdbCartItem).device.id);
  };

  private findProduct(avdbPricingProductType?: AvdbPricingProductType): AvdbPricingProduct | undefined {
    if (this.cartItemMode === CartItemMode.CONFIRMATION) {
      return undefined;
    }
    return avdbPricingProductType?.products.find((product) => product.partKey === this.sku);
  }

  private getPrice(avdbPricingProduct?: AvdbPricingProduct): Money | undefined {
    if (this.cartItemMode === CartItemMode.CONFIRMATION) {
      return this.item.orderPrice;
    }
    return avdbPricingProduct?.price;
  }

  private findAvdbPricingProductType(avdbPricingResponse: AvdbPricingResponse): AvdbPricingProductType | undefined {
    if (this.cartItemMode === CartItemMode.CONFIRMATION) {
      return undefined;
    }
    return avdbPricingResponse.devices
      .find((device) => device.id === (this.legacySubscriptionExpiringResponse?.devicePk ?? this.item?.device?.id))
      ?.avdbProductTypes.find((avdbProductType) =>
        avdbProductType.products.some((product) => product.partKey === this.sku)
      );
  }
}

interface AsyncData {
  avdbPricingProductType?: AvdbPricingProductType;
  price?: Money;
}
