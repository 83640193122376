import { Component, Input } from '@angular/core';
import { Order } from '../../../../../shared/models/order.model';

@Component({
  selector: 'fly-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  standalone: false
})
export class PaymentDetailsComponent {
  @Input({ required: true }) orderInfo: Order;
}
