import { Component, Input, OnInit } from '@angular/core';
import { AvdbPricingBundleType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { AvdbPricingDeviceBundleAvdbType } from 'src/app/shared/models/avdb-pricing/avdb-pricing-device-bundle-avdb-type.model';
import { AvdbPricingDeviceBundle } from 'src/app/shared/models/avdb-pricing/avdb-pricing-device-bundle.model';
import { AvdbPricingDeviceModel } from 'src/app/shared/models/avdb-pricing/avdb-pricing-device-model.model';
import { AvdbPricingDevice } from 'src/app/shared/models/avdb-pricing/avdb-pricing-device.model';
import { AvdbPricingResponse } from 'src/app/shared/models/avdb-pricing/avdb-pricing-response.model';

@Component({
  selector: 'fly-coverage-details-avdb-bundle-device',
  templateUrl: './coverage-details-avdb-bundle-device.component.html',
  styleUrls: ['./coverage-details-avdb-bundle-device.component.scss'],
  standalone: false
})
export class CoverageDetailsAvdbBundleDeviceComponent implements OnInit {
  @Input({ required: true }) bundleType: AvdbPricingBundleType;
  @Input() deviceModel: AvdbPricingDeviceModel;
  @Input() deviceBundle: AvdbPricingDeviceBundle;
  @Input() avdbPricing: AvdbPricingResponse;
  protected readonly AvdbPricingBundleType = AvdbPricingBundleType;
  avdbTypes: AvdbPricingDeviceBundleAvdbType[];
  matchingDevice?: AvdbPricingDevice;

  ngOnInit(): void {
    if (this.deviceModel != null) {
      this.matchingDevice = this.avdbPricing.devices.find((device) => device.modelID === this.deviceModel.modelID);
      this.avdbTypes = this.deviceModel.deviceModelAvdbTypes;
    } else if (this.deviceBundle != null) {
      this.matchingDevice = this.avdbPricing.devices.find(
        (device) => device.modelID === this.deviceBundle.deviceModelID
      );
      this.avdbTypes = this.deviceBundle.avdbTypes;
    }
  }
}
