<g-heading
  class="text-center"
  [content]="errorOccurred ? 'PREPARATION FAILED' : 'PREPARING'"
  size="2">
</g-heading>
@if (!errorOccurred) {
  <div class="text-center">
    <p class="mb-4">Please do not refresh or leave this page as it may disrupt your cart.</p>
    <p class="mb-4">We are preparing your cart and verifying any applicable aircraft for checkout.</p>
    <p>This may take a few minutes to complete.</p>
  </div>
  <g-loader
    class="loader text-center"
    type="dark"></g-loader>
}
<ng-container [ngTemplateOutlet]="content"></ng-container>
@if (errorOccurred) {
  <g-flex class="return-btn-container">
    <g-button
      theme="dark"
      class="text-center"
      (click)="returnToCart.emit()">
      Return to Cart
    </g-button>
  </g-flex>
}

<ng-template #content>
  @switch (currentState) {
    @case (State.AircraftPromotion) {
      <fly-cart-aircraft-promotion
        (promotionCompleted)="currentState = State.CartValidation"
        (errorOccurred)="errorOccurred = true">
      </fly-cart-aircraft-promotion>
    }
    @case (State.CartValidation) {
      <fly-cart-validation
        [userConfig]="userConfig"
        [billingAccountId]="billingAccountId"
        (validationCompleted)="currentState = State.CheckoutRedirect"
        (errorOccurred)="errorOccurred = true"
        (returnToCart)="returnToCart.emit()">
      </fly-cart-validation>
    }
    @case (State.CheckoutRedirect) {
      <fly-checkout-redirect
        [userConfig]="userConfig"
        [cartId]="cartId"
        [billingAccountId]="billingAccountId"
        (errorOccurred)="errorOccurred = true">
      </fly-checkout-redirect>
    }
  }
</ng-template>
