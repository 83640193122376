<ng-template #loading>
  <fly-cart-item [cartItemMode]="cartItemMode"> </fly-cart-item>
</ng-template>

<ng-template #error>
  <fly-cart-item
    [cartItem]="item"
    [legacySubscriptionExpiringResponse]="legacySubscriptionExpiringResponse"
    [cartItemType]="cartItemType"
    [headerText]="skuProduct?.display ?? item?.bundle?.partKey"
    [cartItemMode]="cartItemMode"
    [hasError]="hasError"
    [removeCartItem$]="removeCartItem$"
    (removeCartItemEvent)="removeCartItem.emit($event)"
    (removingCartItem)="removingCartItem.emit($event)">
  </fly-cart-item>
</ng-template>

@if (!hasError && skuProduct != null) {
  @if (asyncData$ | flyObservableStatus | async; as status) {
    @if (status.status === ObservableStatus.LOADING) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
    @if (status.status === ObservableStatus.ERROR) {
      <ng-container [ngTemplateOutlet]="error"></ng-container>
    }
    @if (status.status === ObservableStatus.LOADED && status.value != null) {
      <fly-cart-item
        [presentInCurrentCart]="presentInCurrentCart"
        [skuProduct]="skuProduct"
        [price]="status.value.price"
        [cartItem]="item"
        [legacySubscriptionExpiringResponse]="legacySubscriptionExpiringResponse"
        [cartItemType]="cartItemType"
        [headerText]="skuProduct.display"
        [cartItemMode]="cartItemMode"
        [removeCartItem$]="removeCartItem$"
        [hasCollapsedDetails]="
          bundleType != null && (status.value.aircraftBundle != null || status.value.deviceBundle != null)
        "
        [kindOfCollapsedDetails]="'Bundle'"
        (expandCollapsedDetailsEvent)="expandCollapsedBundleDetails = $event"
        (removeCartItemEvent)="removeCartItem.emit($event)"
        (removingCartItem)="removingCartItem.emit($event)">
        <div body>
          @if (bundleType === AvdbPricingBundleTypes.PILOTPAK || bundleType === AvdbPricingBundleTypes.ONEPAK) {
            <p>
              <strong>Covered Aircraft: </strong>
              <a href="{{ flyLegacyAircraftDetailsUrl }}">
                {{
                  legacySubscriptionExpiringResponse
                    ? legacySubscriptionExpiringResponse.tailNumber +
                      ' (' +
                      legacySubscriptionExpiringResponse.aircraftName +
                      ')'
                    : item?.aircraft?.id + ' (' + item?.aircraft?.name + ')'
                }}
              </a>
            </p>
          } @else if (bundleType === AvdbPricingBundleTypes.DEVICE) {
            <p>
              <strong>Covered Device: </strong>
              <a href="{{ flyLegacyDeviceDetailsUrl }}">
                {{
                  legacySubscriptionExpiringResponse
                    ? legacySubscriptionExpiringResponse.deviceModelName +
                      ' (' +
                      legacySubscriptionExpiringResponse.deviceId +
                      ')'
                    : item?.device?.name + ' (' + item?.device?.displaySerial + ')'
                }}
              </a>
            </p>
          }
          <p> <strong>Duration: </strong>1 Year </p>
          @if (item?.existingSubscriptionUuid == null && cartItemMode === CartItemMode.CHECKOUT && futureStartEnabled) {
            <p>
              <strong>Start Date: </strong>
              @if (item?.futureStartDate == null) {
                Today
              } @else {
                {{ item?.futureStartDate! | flyFormattedDate }}
              }
              <button
                class="link-styled-button"
                (click)="changeStartDateModal.open()">
                Change
              </button>
            </p>
          }
          <p> <strong>Part Number:</strong> {{ skuProduct.sku }} </p>
          @if (item?.existingSubscriptionUuid != null && subscriptionsEarlyRenewalEnabled) {
            @if (cartItemMode === CartItemMode.CHECKOUT && newExpiration != null) {
              <p> This purchase will extend your existing subscription to {{ newExpiration | flyFormattedDate }}. </p>
            } @else if (cartItemMode === CartItemMode.CONFIRMATION) {
              <br />
              <p>Your existing subscription has been extended by <strong>1 Year</strong>.</p>
              <p>This may take a few minutes to be displayed.</p>
            }
          }
          @if (
            cartItemMode === CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION &&
            legacySubscriptionExpiringResponse.expireDateTime != null
          ) {
            <p class="expiration-date">
              Current coverage will expire on
              {{ legacySubscriptionExpiringResponse.expireDateTime | flyFormattedDate }}
            </p>
          }
        </div>

        @if (bundleType != null && (status.value.aircraftBundle != null || status.value.deviceBundle != null)) {
          <div collapsedDetails>
            @if (expandCollapsedBundleDetails) {
              @if (bundleType === AvdbPricingBundleType.PILOTPAK || bundleType === AvdbPricingBundleType.ONEPAK) {
                @if (status.value.aircraftBundle != null) {
                  <fly-coverage-details-avdb-bundle
                    [bundleType]="bundleType"
                    [avdbPricingResponse]="avdbPricingResponse"
                    [qualifyingDeviceModelsOnAircraft]="status.value.aircraftBundle.qualifyingDeviceModels">
                  </fly-coverage-details-avdb-bundle>
                }
              } @else if (bundleType === AvdbPricingBundleType.DEVICE) {
                @if (status.value.deviceBundle != null) {
                  <fly-coverage-details-avdb-bundle
                    [bundleType]="bundleType"
                    [avdbPricingResponse]="avdbPricingResponse"
                    [deviceBundle]="status.value.deviceBundle">
                  </fly-coverage-details-avdb-bundle>
                }
              }
            }
          </div>
        }
      </fly-cart-item>
    }
  }
} @else {
  <ng-container [ngTemplateOutlet]="error"></ng-container>
}

<fly-cart-item-change-start-date-modal
  #changeStartDateModal
  [item]="item!"
  [itemType]="'avdbBundle'"></fly-cart-item-change-start-date-modal>
