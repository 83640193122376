import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AvdbDeviceIdType,
  AvdbPricingBundleType,
  AvdbSubscription,
  CartItemType,
  SkuProduct,
  SubscriptionResponse
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { defaultIfEmpty, forkJoin, from, Observable, toArray } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CartItemMode } from '../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../shared/enums/observable-status-enum';
import { CategorizedCart } from '../../../../shared/models/cart/categorized-cart.model';
import { BillingAccount } from '../../../../shared/models/subscriptions/billing-account.model';
import { LegacySubscriptionExpiringResponse } from '../../../../shared/models/subscriptions/legacy-subscription-expiring-response.model';
import { AccountsService } from '../../../../shared/services/accounts.service';
import { AvdbSubscriptionsService } from '../../../../shared/services/avdb-subscriptions.service';
import { CartService } from '../../../../shared/services/cart.service';
import { CollectionsService } from '../../../../shared/services/collections.service';
import { LegacySubscriptionsService } from '../../../../shared/services/legacy-subscriptions.service';
import { SkuService } from '../../../../shared/services/sku.service';

@Component({
  selector: 'fly-expiring-subscriptions',
  templateUrl: './expiring-subscriptions.component.html',
  styleUrls: ['./expiring-subscriptions.component.scss'],
  standalone: false
})
export class ExpiringSubscriptionsComponent implements OnInit {
  private readonly featuresService = inject(FeaturesService);
  daysFromToday: string;
  asyncData$: Observable<AsyncData>;
  cartItemMode: CartItemMode = CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION;
  autoRenewalEnabled: boolean = false;
  protected readonly ObservableStatus = ObservableStatus;
  protected readonly AvdbPricingBundleType = AvdbPricingBundleType;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    readonly accountsService: AccountsService,
    readonly legacySubscriptionsService: LegacySubscriptionsService,
    readonly avdbSubscriptionsService: AvdbSubscriptionsService,
    readonly skuService: SkuService,
    readonly cartService: CartService,
    readonly collectionsService: CollectionsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.daysFromToday = this.route.snapshot.queryParams.days ?? 30;
  }

  ngOnInit(): void {
    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.AR_EXPIRING_SUBSCRIPTION_EMAIL)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((active) => {
        this.autoRenewalEnabled = active;
      });

    this.asyncData$ = forkJoin([
      this.legacyIssuedBasedExpiringAvdbSubscriptions$(),
      this.yarmouthBasedAvdbSubscriptions$(),
      this.currentCartCategorized$()
    ]).pipe(
      mergeMap(
        ([legacyIssuedBasedExpiringAvdbSubscriptions, yarmouthBasedAvdbSubscriptions, categorizedCurrentCart]) => {
          const skusToFetch = [
            ...new Set(
              legacyIssuedBasedExpiringAvdbSubscriptions.map((sub) => (sub.bundleSKU == null ? sub.sku : sub.bundleSKU))
            )
          ];

          return this.skuService.skuProductsMap$(skusToFetch).pipe(
            map((skuProducts) => {
              const categorizedSubscriptions = this.categorizeExpiringLegacySubscriptions(
                legacyIssuedBasedExpiringAvdbSubscriptions,
                skuProducts
              );

              let expiringOnePakSubscriptions = this.gatherExpiringIssueBasedBundles(
                categorizedSubscriptions.onepak,
                AvdbPricingBundleType.ONEPAK
              );
              let expiringPilotPakSubscriptions = this.gatherExpiringIssueBasedBundles(
                categorizedSubscriptions.pilotpak,
                AvdbPricingBundleType.PILOTPAK
              );
              let expiringDeviceBundleSubscriptions = this.gatherExpiringIssueBasedBundles(
                categorizedSubscriptions.device,
                AvdbPricingBundleType.DEVICE
              );

              let [expiringDeviceBundleSubscriptionsForPortables, expiringDeviceBundleSubscriptionsForAircraftDevices] =
                this.collectionsService.partition(
                  expiringDeviceBundleSubscriptions,
                  (expiringDeviceBundleSubscription) =>
                    expiringDeviceBundleSubscription.deviceTypeId === AvdbDeviceIdType.PORTABLE_DEVICE_SERIAL_NUMBER
                );
              let [expiringAvdbSubscriptionsForPortables, expiringAvdbSubscriptionsForAircraftDevices] =
                this.collectionsService.partition(
                  categorizedSubscriptions.avdb,
                  (expiringAvdbSubscription) =>
                    expiringAvdbSubscription.deviceTypeId === AvdbDeviceIdType.PORTABLE_DEVICE_SERIAL_NUMBER
                );

              expiringOnePakSubscriptions = this.removeYarmouthBasedSubscriptions(
                expiringOnePakSubscriptions,
                yarmouthBasedAvdbSubscriptions,
                true,
                true,
                false
              );
              expiringPilotPakSubscriptions = this.removeYarmouthBasedSubscriptions(
                expiringPilotPakSubscriptions,
                yarmouthBasedAvdbSubscriptions,
                true,
                true,
                false
              );
              expiringDeviceBundleSubscriptionsForAircraftDevices = this.removeYarmouthBasedSubscriptions(
                expiringDeviceBundleSubscriptionsForAircraftDevices,
                yarmouthBasedAvdbSubscriptions,
                true,
                false,
                false
              );
              expiringDeviceBundleSubscriptionsForPortables = this.removeYarmouthBasedSubscriptions(
                expiringDeviceBundleSubscriptionsForPortables,
                yarmouthBasedAvdbSubscriptions,
                true,
                false,
                true
              );
              expiringAvdbSubscriptionsForAircraftDevices = this.removeYarmouthBasedSubscriptions(
                expiringAvdbSubscriptionsForAircraftDevices,
                yarmouthBasedAvdbSubscriptions,
                false,
                false,
                false
              );
              expiringAvdbSubscriptionsForPortables = this.removeYarmouthBasedSubscriptions(
                expiringAvdbSubscriptionsForPortables,
                yarmouthBasedAvdbSubscriptions,
                false,
                false,
                true
              );

              const expiringAircraftBundleSubscriptions = [
                ...expiringOnePakSubscriptions,
                ...expiringPilotPakSubscriptions
              ];
              expiringDeviceBundleSubscriptions = [
                ...expiringDeviceBundleSubscriptionsForAircraftDevices,
                ...expiringDeviceBundleSubscriptionsForPortables
              ];
              const expiringAvdbSubscriptions = [
                ...expiringAvdbSubscriptionsForAircraftDevices,
                ...expiringAvdbSubscriptionsForPortables
              ];

              return {
                expiringIssuedBasedAvdbSubscriptions: expiringAvdbSubscriptions,
                expiringIssueBasedAircraftBundleSubscriptionsGroupedByAircraft: this.collectionsService.groupBy(
                  expiringAircraftBundleSubscriptions,
                  (bundle) => bundle.tailNumber
                ),
                expiringIssueBasedDeviceBundleSubscriptionsGroupedByDevice: this.collectionsService.groupBy(
                  expiringDeviceBundleSubscriptions,
                  (bundle) => bundle.devicePk
                ),
                totalNumberOfExpiringIssuedBasedSubscriptions:
                  expiringAvdbSubscriptions.length +
                  expiringAircraftBundleSubscriptions.length +
                  expiringDeviceBundleSubscriptions.length,
                skuProductsMap: skuProducts,
                currentCart: categorizedCurrentCart
              };
            })
          );
        }
      )
    );
  }

  navigateToCartPage(): void {
    this.router.navigate(['/purchasing/cart']);
  }

  categorizeExpiringLegacySubscriptions(
    legacySubscriptionExpiringResponse: LegacySubscriptionExpiringResponse[],
    skuProductsMap: Map<string, SkuProduct>
  ): {
    avdb: LegacySubscriptionExpiringResponse[];
    onepak: LegacySubscriptionExpiringResponse[];
    pilotpak: LegacySubscriptionExpiringResponse[];
    device: LegacySubscriptionExpiringResponse[];
  } {
    return legacySubscriptionExpiringResponse.reduce(
      (result, response) => {
        const bundleSKU = response.bundleSKU;
        const skuProduct = bundleSKU == null ? skuProductsMap.get(response.sku) : skuProductsMap.get(bundleSKU);
        const bundleType = skuProduct?.bundleType;
        switch (bundleType) {
          case AvdbPricingBundleType.DEVICE:
            result[AvdbPricingBundleType.DEVICE].push(response);
            break;
          case AvdbPricingBundleType.ONEPAK:
            result[AvdbPricingBundleType.ONEPAK].push(response);
            break;
          case AvdbPricingBundleType.PILOTPAK:
            result[AvdbPricingBundleType.PILOTPAK].push(response);
            break;
          default:
            result[CartItemType.DATABASE].push(response);
            break;
        }
        return result;
      },
      {
        device: [] as LegacySubscriptionExpiringResponse[],
        onepak: [] as LegacySubscriptionExpiringResponse[],
        pilotpak: [] as LegacySubscriptionExpiringResponse[],
        avdb: [] as LegacySubscriptionExpiringResponse[]
      }
    );
  }

  currentCartCategorized$(): Observable<CategorizedCart> {
    return this.cartService
      .getCurrentCart()
      .pipe(map((currentCart) => this.cartService.categorizeCartItems(currentCart)));
  }

  legacyIssuedBasedExpiringAvdbSubscriptions$(): Observable<LegacySubscriptionExpiringResponse[]> {
    return this.legacySubscriptionsService.getUserExpiringLegacyAvdbSubscriptions(this.daysFromToday);
  }

  yarmouthBasedAvdbSubscriptions$(): Observable<AvdbSubscription[]> {
    return this.accountsService.getAccounts().pipe(
      defaultIfEmpty([]),
      mergeMap((billingAccounts: BillingAccount[]) =>
        from(billingAccounts).pipe(
          mergeMap((billingAccount: BillingAccount) =>
            this.avdbSubscriptionsService.getAvdbSubscriptions(billingAccount.billingAccountId, undefined, undefined)
          ),
          mergeMap((result: SubscriptionResponse<AvdbSubscription[]>) => result.data)
        )
      ),
      toArray()
    );
  }

  removeYarmouthBasedSubscriptions(
    legacyIssueBasedExpiringAvdbSubscriptions: LegacySubscriptionExpiringResponse[],
    yarmouthBasedAvdbSubscriptions: AvdbSubscription[],
    isBundle: boolean,
    isAircraftBundle: boolean,
    isForPortable: boolean
  ): LegacySubscriptionExpiringResponse[] {
    const aircraftMatchCondition = (
      legacyIssueBasedExpiringAvdbSubscription: LegacySubscriptionExpiringResponse,
      yarmouthBasedAvdbSubscription: AvdbSubscription
    ): boolean => yarmouthBasedAvdbSubscription.aircraftUuid === legacyIssueBasedExpiringAvdbSubscription.aircraftUuid;
    const deviceMatchCondition = (
      legacyIssueBasedExpiringAvdbSubscription: LegacySubscriptionExpiringResponse,
      yarmouthBasedAvdbSubscription: AvdbSubscription
    ): boolean =>
      yarmouthBasedAvdbSubscription.devices?.some(
        (device) => device.id === legacyIssueBasedExpiringAvdbSubscription.deviceId
      );
    const skuMatchCondition = (
      legacyIssueBasedExpiringAvdbSubscription: LegacySubscriptionExpiringResponse,
      yarmouthBasedAvdbSubscription: AvdbSubscription
    ): boolean =>
      yarmouthBasedAvdbSubscription.sku ===
      (isBundle ? legacyIssueBasedExpiringAvdbSubscription.bundleSKU : legacyIssueBasedExpiringAvdbSubscription.sku);

    return legacyIssueBasedExpiringAvdbSubscriptions.filter(
      (legacyIssueBasedExpiringAvdbSubscription) =>
        !yarmouthBasedAvdbSubscriptions.some(
          (yarmouthBasedAvdbSubscription) =>
            skuMatchCondition(legacyIssueBasedExpiringAvdbSubscription, yarmouthBasedAvdbSubscription) &&
            (isForPortable
              ? true
              : aircraftMatchCondition(legacyIssueBasedExpiringAvdbSubscription, yarmouthBasedAvdbSubscription)) &&
            (isAircraftBundle
              ? true
              : deviceMatchCondition(legacyIssueBasedExpiringAvdbSubscription, yarmouthBasedAvdbSubscription))
        )
    );
  }

  gatherExpiringIssueBasedBundles(
    expiringBundles: LegacySubscriptionExpiringResponse[],
    bundleType: AvdbPricingBundleType
  ): LegacySubscriptionExpiringResponse[] {
    const filteredBundles: LegacySubscriptionExpiringResponse[] = [];

    const isDuplicate = (
      existing: LegacySubscriptionExpiringResponse,
      expiringBundle: LegacySubscriptionExpiringResponse
    ): boolean => {
      switch (bundleType) {
        case AvdbPricingBundleType.ONEPAK:
        case AvdbPricingBundleType.PILOTPAK:
          return existing.bundleSKU === expiringBundle.bundleSKU && existing.tailNumber === expiringBundle.tailNumber;
        case AvdbPricingBundleType.DEVICE:
          return existing.bundleSKU === expiringBundle.bundleSKU && existing?.devicePk === expiringBundle.devicePk;
        default:
          return false;
      }
    };

    const filteredExpiringBundles =
      bundleType === AvdbPricingBundleType.ONEPAK || bundleType === AvdbPricingBundleType.PILOTPAK
        ? expiringBundles.filter((bundle) => bundle.tailNumber != null)
        : expiringBundles;

    for (const expiringBundle of filteredExpiringBundles) {
      let existingBundle = filteredBundles.find((existing) => isDuplicate(existing, expiringBundle));

      if (existingBundle == null) {
        filteredBundles.push(expiringBundle);
      } else if (new Date(expiringBundle.expireDateTime) < new Date(existingBundle.expireDateTime)) {
        existingBundle = expiringBundle;
      }
    }

    return filteredBundles;
  }
}

interface AsyncData {
  expiringIssuedBasedAvdbSubscriptions: LegacySubscriptionExpiringResponse[];
  expiringIssueBasedAircraftBundleSubscriptionsGroupedByAircraft: Map<string, LegacySubscriptionExpiringResponse[]>;
  expiringIssueBasedDeviceBundleSubscriptionsGroupedByDevice: Map<string, LegacySubscriptionExpiringResponse[]>;
  totalNumberOfExpiringIssuedBasedSubscriptions: number;
  skuProductsMap: Map<string, SkuProduct>;
  currentCart: CategorizedCart;
}
