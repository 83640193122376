import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartItemType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { EMPTY, Observable } from 'rxjs';
import { CartItemMode } from '../../../../../../shared/enums/cart-item-mode-enum';
import { CartItem } from '../../../../../../shared/models/cart/cart-item';
import { Cart } from '../../../../../../shared/models/cart/cart.model';
import { DiscountCodeCartItem } from '../../../../../../shared/models/cart/discount-code-cart-item';
import { CartService } from '../../../../../../shared/services/cart.service';

@Component({
  selector: 'fly-cart-item-discount-code',
  templateUrl: './cart-item-discount-code.component.html',
  styleUrl: './cart-item-discount-code.component.scss',
  standalone: false
})
export class CartItemDiscountCodeComponent implements OnInit {
  @Input({ required: true }) item: DiscountCodeCartItem;
  @Output() readonly removeDiscountCodeCartItem: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly removingCartItem: EventEmitter<boolean> = new EventEmitter();
  cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  hasError: boolean = false;
  cartItemType: CartItemType = CartItemType.DISCOUNT_CODE;
  removeCartItem$: Observable<Cart>;

  constructor(readonly cartService: CartService) {}

  ngOnInit(): void {
    this.removeCartItem$ = this.item == null ? EMPTY : this.cartService.removePromoCodeFromCurrentCart(this.item.id);
  }
}
